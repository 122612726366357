import { inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable } from 'rxjs';
import { WorkerStateService } from './worker/worker-state.service';

@Injectable({
  providedIn: 'root',
})
export class NewVersionCheckerService {
  private readonly swUpdate = inject(SwUpdate);
  private readonly worker = inject(WorkerStateService);
  private isNewVersionAvailableSub = new BehaviorSubject<boolean>(false);
  private readonly lastUpdateNotificationKey = 'lastUpdateNotificationDate';

  get isNewVersionAvailable$(): Observable<boolean> {
    return this.isNewVersionAvailableSub.asObservable();
  }

  applyUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate
        .activateUpdate()
        .then(() => document.location.reload())
        .catch(error => console.error('Failed to apply updates:', error));
    } else {
      document.location.reload();
    }
  }

  subscribeForVersionUpdates(): void {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Загружаем новую версию hermes: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Текущая версия hermes: ${evt.currentVersion.hash}`);
          console.log(`Новая версия hermes доступная для установки: ${evt.latestVersion.hash}`);

          if (this.shouldNotify()) {
            this.isNewVersionAvailableSub.next(true);
            this.updateLastNotificationDate();
          }
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Ошибка установки новой версии hermes '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  private shouldNotify(): boolean {
    if (!this.worker?.currentWorkerValue?.employeeId) {
      return false;
    }

    // Те, кто получают обновления всегда
    const employeeIds = [167, 183, 75, 739];
    if (employeeIds.includes(this.worker.currentWorkerValue.employeeId)) {
      return true;
    }

    const lastNotificationDate = localStorage.getItem(this.lastUpdateNotificationKey);
    if (!lastNotificationDate) {
      return true;
    }

    const lastDate = new Date(lastNotificationDate);
    const today = new Date();
    return lastDate.toDateString() !== today.toDateString();
  }

  private updateLastNotificationDate(): void {
    const today = new Date().toISOString();
    localStorage.setItem(this.lastUpdateNotificationKey, today);
  }
}
